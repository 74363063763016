<template>
  <b-card v-if="!isStarted">
    <b-row class="justify-content-between align-items-center">
      <b-col cols="12" sm="12" md="12" lg="4" xl="4">
        <b-alert
            variant="warning"
            class="mb-0"
            show>
          <div class="alert-body" >
            <feather-icon
                icon="ClockIcon"
                class="mr-50"/>
            {{ $t('This Event starts in') }}:
            <span v-if="eventStart.day">{{ eventStart.day }} {{ $t('Days') }}, </span>
            <span v-if="eventStart.hours">{{ eventStart.hours }} {{ $t('Hours') }} & </span>
            <span v-if="eventStart.minutes">   {{ eventStart.minutes }} {{ $t('Minutes') }} </span>
          </div>
        </b-alert>
      </b-col>
      <b-col cols="12" sm="12" md="12" lg="2" xl="2" class="d-flex justify-content-end">
        <b-button
            v-if="!isStarted"
            :to="{ name: 'booking-cancellation',
                    params: {bookingid: booking.id} }"
            variant="danger"
            class="mr-2"
            type="submit"
            size="sm"
        > {{ $t('Cancel event') }}
        </b-button>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BAlert,
  BCard,
  BCardGroup,
  BCardText,
  BRow,
  BCol,
  BCardImg,
  BCardTitle,
  BCardBody,
  BBadge,
  BIcon,
  BButton,
  BAvatar,
  BTable,
} from "bootstrap-vue";

import {setStartDate} from "@/mixins/setTimeMixin";

export default {
  name: "EventCancel",
  props: {
    booking: {
      type: Object,
      required: true
    },
  },
  mixins: [setStartDate],

  data() {
    return {
      eventStart: {
        day: 0,
        hours: 0,
        minutes: 0
      },

    }

  },

  components: {

    BAlert,
    BCard,
    BCardGroup,
    BCardText,
    BRow,
    BCol,
    BCardImg,
    BCardTitle,
    BCardBody,
    BBadge,

    BIcon,
    BButton,
    BTable,
    BAvatar,
  },
  mounted() {
    this.setStartDate()
  },
  computed: {
    isStarted() {
      if (this.eventStart.day < 0 && this.eventStart.hours < 0 && this.eventStart.minutes < 0) return true
      return false
    }
  }
}
</script>

<style scoped>

</style>
