<template>
  <b-card class="p-md-4 p-0">
    <b-row class="justify-content-between">
      <b-col cols="12" sm="12" md="12" lg="3" xl="3">
        <h4 class="h4 mb-3">
          {{ $t('Bookings & Participants') }}
        </h4>
        <div class="card_info_item">
          <div class="card_content">
            <p class="info_item_title">{{ $t('Registration Deadline') }}</p>
            <p class="info_item_txt"> {{ deadlineDate }}</p>
          </div>
        </div>
        <div class="card_info_item">
          <div class="card_content">
            <p class="info_item_title">{{ $t('Booking type') }}</p>
            <p class="info_item_txt" v-if="event.booking_type == 2">{{ $t('Dynamic appointment') }}</p>
            <p class="info_item_txt" v-else>{{ $t('Fixed appointment') }}</p>
          </div>
        </div>
        <div class="card_info_item">
          <div class="card_content">
            <p class="info_item_title">{{ $t('EventDuration') }}</p>
            <p class="info_item_txt">{{ event.duration }}</p>
          </div>
        </div>
        <div class="card_info_item">
          <div class="card_content">
            <p class="info_item_title">{{ $t('Minimum Participants') }}</p>
            <p class="info_item_txt">{{ event.min_members }} {{ $t('Participants') }}</p>
          </div>
        </div>
        <div class="card_info_item">
          <div class="card_content">
            <p class="info_item_title">{{ $t('Maximum Participants') }}</p>
            <p class="info_item_txt">{{ event.max_members }} {{ $t('Participants') }}</p>
          </div>
        </div>
      </b-col>
      <b-col cols="12" sm="12" md="12" lg="3" xl="3">
        <h4 class="h4 mb-3">
          {{ $t('Event Appointments') }}
        </h4>
        <!--         dynamic aAppointments -->
        <div class="card_info_item pl-0 card-with-image d-flex" v-if="event.booking_type == 2"
             v-for="(slot,index) in event.timeslots" :key="slot.id">
          <div class="image_side with_icon">
            <div class="icon_box">
              <feather-icon style="stroke: #016565" size="20" icon="CalendarIcon"/>
            </div>
          </div>
          <div class="card_content">
            <p class="info_item_title">{{ slot.topic }} </p>
            <p class="info_item_txt"> {{ slot.booking_time }} {{ $t('Minutes') }}</p>
          </div>
        </div>
        <!--        fixed Appointment -->
        <div class="card_info_item pl-0 card-with-image d-flex"
             v-if="event.booking_type == 0 || event.booking_type == 1"
             v-for="(slot,index) in event.timeslots" :key="slot.id">
          <div class="image_side with_icon">
            <div class="icon_box">
              <feather-icon style="stroke: #016565" size="20" icon="CalendarIcon"/>
            </div>
          </div>
          <div class="card_content">
            <p class="info_item_title">{{ $t('Appointment') }} {{ index + 1 }}</p>
            <p class="info_item_txt"> {{ changeDateFormat(slot.start_date) }} {{ slot.start_time }} - <br>
              {{ changeDateFormat(slot.end_date) }} {{ slot.end_time }}</p>
          </div>
        </div>
      </b-col>
      <b-col cols="12" sm="12" md="12" lg="3" xl="3">
        <h4 class="h4 mb-3">
          {{ $t('Event Cost') }}
        </h4>
        <div class="card_info_item pl-0 card-with-image d-flex">
          <div class="image_side with_icon">
            <div class="icon_box">
              <feather-icon style="stroke: #016565" size="20" icon="DollarSignIcon"/>
            </div>
          </div>
          <div class="card_content">
            <p class="info_item_title">{{ $t('Credits Cost') }}</p>
            <p class="info_item_txt"> {{ event.credits }} Credits</p>
          </div>
        </div>
        <div class="card_info_item margin_without_image empty_card_image">
          <div class="card_content">
            <p class="info_item_title">{{ $t('Payment type') }}</p>
            <p class="info_item_txt" v-if="event.payment_type == 0"> {{ $t('One time payable event') }}</p>
            <p class="info_item_txt" v-if="event.payment_type == 1"> {{ $t('Payment per participant') }}</p>
          </div>
        </div>
        <div class="card_info_item margin_without_image empty_card_image">
          <div class="card_content">
            <p class="info_item_title">{{ $t('Booking/Payment method') }}</p>
            <p class="info_item_txt" v-if="event.payment_method == 1">{{ $t('Whole event completely sold') }}</p>
            <p class="info_item_txt" v-if="event.payment_method == 2"> {{ $t('Place sold at the event') }}</p>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>

import moment from 'moment'
import {
  BRow,
  BCard,
  BImg,
  BCol,
  BButton,
} from 'bootstrap-vue'

export default {
  props: {
    event: {
      type: Object,
      required: true
    },
  },
  components: {
    BRow,
    BCard,
    BImg,
    BCol,
    BButton,
  },
  methods: {
    changeDateFormat(date) {
      return moment(date, "YYYY-MM-DD").format("DD.MM.YYYY");
    },

  },
  computed: {
    deadlineDate() {
      return moment(this.event.deadline, "YYYY-MM-DD HH:mm").format("DD.MM.YYYY");
    },
  }
}
</script>

