<template>

  <div v-if="BOOKING_DETAILS">
    <EventCancel :booking="BOOKING_DETAILS"/>
    <EventGeneral :event="BOOKING_DETAILS.event" :expert="BOOKING_DETAILS.expert"/>
    <EventInformation :event="BOOKING_DETAILS.event"/>
    <EventGallery :event="BOOKING_DETAILS.event"/>
  </div>
</template>
<script>

import {mapActions, mapGetters} from "vuex";
import EventGeneral from "@/views/bookings/single-booking-parts/EventGeneral";
import EventInformation from "@/views/bookings/single-booking-parts/EventInformation";
import EventGallery from "@/views/bookings/single-booking-parts/EventGallery";
import EventCancel from "@/views/bookings/single-booking-parts/EventCancel";

export default {
  components: {
    EventCancel,
    EventGallery,
    EventInformation,
    EventGeneral,
  },


  computed: {
    ...mapGetters(['BOOKING_DETAILS']),

  },
  methods: {
    ...mapActions(['GET_BOOKING_DETAILS']),
  },
  mounted() {
    this.GET_BOOKING_DETAILS(this.$route.params.bookingid)
  }
};
</script>


<style lang="scss">
.card_info_item {
  margin-bottom: 30px;

  &.empty_card_image {
    margin-left: 60px;
  }

  &.margin_without_image {
    margin-left: 60px;
  }

  &.card-with-image {
    border-left: none;

    .image_side {
      padding-right: 15px;
      width: 65px;

      display: flex;
      align-items: center;
      justify-content: end;

      &.with_icon {
        display: flex;
        align-items: center;
        justify-content: end;
      }

      .icon_box {
        background: rgb(233, 241, 242);
        border-radius: 5px;
        justify-content: center;
        display: flex;
        padding: 15px;

      }
    }
  }

  .info_item_title {
    font-size: 18px;
    padding-bottom: 5px;
    font-weight: 500;
    margin-bottom: 0;
  }

  .card_content {
    border-left: 1px solid #d8d6de;
    padding: 10px 15px;
  }
}

.book_event {
  background-color: rgb(1, 101, 101) !important;
  border-color: rgb(1, 101, 101) !important;
}

@media screen and (max-width: 960px) {
  .card_info_item.card-with-image .image_side,
  .card_info_item.card-with-image .image_side .icon_box {
    display: none;
  }
  .card-with-image {
    flex-direction: column;

  }
  .image_side.with_icon,
  .card_info_item.empty_card_image,
  .card_info_item.card-with-image, {
    margin-left: 0px;
    margin-bottom: 20px;

    .image_side {
      justify-content: start;

      &.with_icon {

        justify-content: start;
      }
    }


  }
  .card-with-image {
    padding-left: 0;
  }
}

</style>